import { CalculatedFee, CalculateServiceFeesRequest, Type } from '@wix/ambassador-service-fees-rules/types';
import { ServiceFeesRules } from '@wix/ambassador-service-fees-rules/http';
import { VirtualDispatchType } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { Experiments } from '@wix/yoshi-flow-editor';
import { SetIsCalculatingServiceFeesPayload } from '../state/checkout/checkout.actions.types';

const CALC_FEES_REQUEST_LABEL = 'restaurants';
const getShippingInfoType = (dispatchType: VirtualDispatchType, isCurbside?: boolean) => {
  if (isCurbside) {
    return Type.CURBSIDE_PICKUP;
  }

  switch (dispatchType) {
    case 'delivery':
      return Type.DELIVERY;
    case 'dine-in':
      return Type.DINE_IN;
    case 'takeout':
      return Type.PICKUP;
    default:
      return Type.UNSPECIFIED_FULFILLMENT_TYPE;
  }
};

export const createCalcFeesRequest = ({
  currency,
  isCurbside,
  subtotal,
  locale,
  isMobile,
  dispatch,
  locationId,
}: {
  currency: string;
  isCurbside?: boolean;
  subtotal: number;
  locale: string;
  isMobile: boolean;
  dispatch: VirtualDispatchType;
  locationId?: string;
}): CalculateServiceFeesRequest => {
  const shippingInfoType = getShippingInfoType(dispatch, isCurbside);
  const localeObj = locale.split('_');

  return {
    label: CALC_FEES_REQUEST_LABEL,
    order: {
      locale: {
        country: localeObj[1],
        languageCode: localeObj[0],
      },
      currency,
      locationId,
      platform: {
        value: isMobile ? 'MOBILE_SITE' : 'SITE',
      },
      priceSummary: {
        subtotal: (subtotal / 100).toFixed(2),
      },
      shippingInfo: {
        logistics: {
          type: shippingInfoType,
        },
      },
    },
  };
};

export const updateCalculatedFees = async ({
  experiments,
  signedInstance,
  currency,
  locationId,
  isCurbside,
  subtotal,
  fedopsLogger,
  setIsCalculatingServiceFees,
  locale,
  isMobile,
  dispatch,
}: {
  experiments: Experiments;
  signedInstance?: string;
  currency: string;
  locale: string;
  locationId?: string;
  isCurbside?: boolean;
  isMobile: boolean;
  subtotal: number;
  fedopsLogger: any;
  dispatch: VirtualDispatchType;
  setIsCalculatingServiceFees: (payload: SetIsCalculatingServiceFeesPayload) => void;
}): Promise<{ calculatedFees?: CalculatedFee[] | undefined; errors?: string[] | undefined }> => {
  const result: {
    calculatedFees?: CalculatedFee[];
    errors?: string[];
  } = {
    calculatedFees: undefined,
    errors: undefined,
  };

  const isServiceFeeExperimentEnabled = experiments.enabled('specs.restaurants.isServiceFeeEnabled-olo-client-dev');
  if (!isServiceFeeExperimentEnabled) {
    return {};
  }

  const headers = { Authorization: signedInstance };
  const calculateServiceFeesApi = ServiceFeesRules('/_api/restaurants').ServiceFeesCalculate()(headers);
  const request = createCalcFeesRequest({ dispatch, isMobile, subtotal, isCurbside, currency, locationId, locale });

  try {
    setIsCalculatingServiceFees({ isCalculating: true });

    fedopsLogger.interactionStarted('calculate-service-fees');
    const { calculatedFees } = await calculateServiceFeesApi.calculateServiceFees(request);
    fedopsLogger.interactionEnded('calculate-service-fees');
    setIsCalculatingServiceFees({ isCalculating: false });

    result.calculatedFees = calculatedFees;
  } catch (e) {
    console.error('Could not calculate service fees', e);
    result.errors = ['error'];
  }
  return result;
};
